<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="grid">
        <div class="col-12">
          <div class="formgrid grid">
            <div class="field col-12 md:col-12">
              <label>Head Akun</label>
              <Dropdown
                v-model="form.coa_head_id"
                :filter="true"
                :options="listHead"
                class="w-full"
                :class="{
                  'p-invalid': v$.form.coa_head_id.$invalid && submitted,
                }"
                input-class="w-full"
                optionValue="id"
                optionLabel="nama"
                @change="loadKelompokAkun"
              />
              <small
                v-if="
                  (v$.form.coa_head_id.$invalid && submitted) ||
                  v$.form.coa_head_id.$pending.$response
                "
                class="p-error"
                >{{ v$.form.coa_head_id.required.$message }}</small
              >
            </div>
            <div class="field col-12 md:col-12">
              <label>Kelompok Akun</label>
              <Dropdown
                v-model="form.coa_kelompok_id"
                :filter="true"
                :options="list_kelompok"
                class="w-full"
                :class="{
                  'p-invalid': v$.form.coa_kelompok_id.$invalid && submitted,
                }"
                input-class="w-full"
                optionValue="id"
                optionLabel="nama"
              />
              <small
                v-if="
                  (v$.form.coa_kelompok_id.$invalid && submitted) ||
                  v$.form.coa_kelompok_id.$pending.$response
                "
                class="p-error"
                >{{ v$.form.coa_kelompok_id.required.$message }}</small
              >
            </div>
            <div class="field col-12 md:col-12">
              <label>Kode</label>
              <InputText
                v-model="form.kode"
                class="w-full"
                input-class="w-full"
                :class="{
                  'p-invalid': v$.form.kode.$invalid && submitted,
                }"
              />
              <small
                v-if="
                  (v$.form.kode.$invalid && submitted) ||
                  v$.form.kode.$pending.$response
                "
                class="p-error"
                >{{ v$.form.kode.required.$message }}</small
              >
            </div>
            <div class="field col-12 md:col-12">
              <label>Nama</label>
              <InputText
                v-model="form.nama"
                :class="{ 'p-invalid': v$.form.nama.$invalid && submitted }"
                class="w-full"
                input-class="w-full"
              />
              <small
                v-if="
                  (v$.form.nama.$invalid && submitted) ||
                  v$.form.nama.$pending.$response
                "
                class="p-error"
                >{{ v$.form.nama.required.$message }}</small
              >
            </div>
            <div class="field col-12 md:col-12">
              <label>Posisi</label>
               <SelectButton v-model="form.posisi" :options="['D', 'K']" aria-labelledby="basic" />
              <small
                v-if="
                  (v$.form.posisi.$invalid && submitted) ||
                  v$.form.posisi.$pending.$response
                "
                class="p-error"
              >
                {{ v$.form.posisi.required.$message }}
              </small>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="$emit('close')"
          class="p-button-text mr-2"
        />
        <Button
          :loading="loading"
          label="Simpan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import errorHandler from '@/helpers/error-handler'
import { helpers, required } from '@vuelidate/validators'
import KelompokAkunService from '@/services/KelompokAkunService'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
    listHead: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      submitted: false,
      list_kelompok: [],
    }
  },
  async mounted() {
    if (this.form.id > 0) {
      this.loadKelompokAkun()
    }
  },
  computed: {
    form() {
      return this.item
    },
  },
  methods: {
    async loadKelompokAkun() {
      const kelompokAkunService = new KelompokAkunService()
      await kelompokAkunService
        .get(`?filter[coa_head_id]=${this.form.coa_head_id}`)
        .then((res) => {
          this.list_kelompok = res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Data Kelompok Akun', this)
        })
    },
    handleSubmit(isFormValid) {
      this.submitted = true
      if (!isFormValid) {
        return
      }
      this.$emit('save', this.form)
    },
  },
  validations() {
    return {
      form: {
        coa_head_id: {
          required: helpers.withMessage('Head Akun harus diisi.', required),
        },
        coa_kelompok_id: {
          required: helpers.withMessage('Kelompok Akun harus diisi.', required),
        },
        kode: {
          required: helpers.withMessage('Kode harus diisi.', required),
        },
        nama: {
          required: helpers.withMessage('Nama harus diisi.', required),
        },
        posisi: {
          required: helpers.withMessage('Posisi harus diisi.', required),
        },
      },
    }
  },
}
</script>

